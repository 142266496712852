<template>
    <section v-show="id_pedido">
        <div class="border-bottom p-2">
            <div class="row mx-0">
                <div style="width:41px;" />
                <div class="col pl-0 text-general f-17 f-600">
                    Cliente
                </div>
            </div>
            <div class="row mx-0 align-items-center my-2">
                <img class="border rounded-circle bg-whitesmoke" :src="cliente.avatar" width="34" height="34" alt="" />
                <p class="mx-2 f-16 text-general">{{ cliente.nombre }}</p>
                <div class="br-20 ml-auto mx-2 text-general">
                    <span class="mx-2">{{ cliente.cant_pedidos }}</span>
                    <i class="f-20 icon-pedidos" />
                </div>
            </div>
            <div class="row mx-0 my-2 align-items-center">
                <div style="width:41px;">
                    <i class="icon-calendar-clock text-general f-22" />
                </div>
                <p class="col pl-0 text-general f-16">{{ pedido.fecha_entrega | helper-fecha('ddd DD MMMM hh:mm a') }}</p>
            </div>
            <div class="row mx-0 my-2">
                <div style="width:41px;">
                    <i class="icon-location text-general f-20" />
                </div>
                <div class="col pl-0 text-general">
                    <p class="text-general f-17 f-500">Dirección de entrega</p>
                    <p class="text-general f-16" v-html="formatearTextoHtml(pedido.direccion)" />
                    <p class=" text-general mt-3">{{ pedido.distancia }} Mts</p>
                </div>
            </div>
            <div class="row mx-0 align-items-center">
                <div style="width:41px;">
                    <i class="icon-shopping f-20 text-general" />
                </div>
                <div class="col pl-0 text-general">
                    <b class="mr-1">
                        Entrega:
                    </b>
                    {{ pedido.cliente_entrega_directa == 1 ? 'Cedis' : 'Vendedor' }}
                </div>
            </div>
            <div class="row mx-0 d-middle">
                <span class="text-gr-general mr-2">Creditos</span>
                <el-tooltip placement="top" content="Deuda Vigente" effect="light">
                    <i class="icon-clock text-gr-general f-18" />
                </el-tooltip>
                <span class="f-14 mr-2">{{ convertMoneyTendero(pedido.saldo_vigente,pedido.idm_moneda) }}</span>

                <el-tooltip placement="top" content="Deuda Vencida" effect="light">
                    <i class="icon-attention-alt text-danger f-18" />
                </el-tooltip>
                <span class="f-14">{{ convertMoneyTendero(pedido.saldo_vencido,pedido.idm_moneda) }}</span>
            </div>
        </div>
        <!-- linea de tiempo -->
        <div class="border-bottom p-2">
            <div class="row mx-0 justify-center">
                <div v-for="(act, index) in historial" :key="index" class="d-middle" style="width:55px;">
                    <div class="rounded-circle position-relative" :class="act.activo?'bg-general3':'bg-gris'" style="width:40px;height:40px;padding:1px">
                        <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                            <i :class="`line-history f-20 ${act.icon} ${act.activo?'text-general':'text-gris'}`" />
                        </div>
                    </div>
                    <div v-show="index != historial.length-1" class="w-25" :class="act.activo?'bg-general3':'bg-gris'" style="height:2px;" />
                </div>
            </div>
            <p class="text-general f-17 f-600 my-2 mb-3 text-center">
                {{ nombreEstado }}
            </p>
            <template v-if="showHistorial">
                <div v-for="(act, index) in historial" :key="index" class="row mx-0">
                    <div class="col-auto">
                        <div class="rounded-circle position-relative" :class="act.activo?'bg-general3':'bg-gris'" style="width:40px;height:40px;padding:1px">
                            <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                                <i :class="`line-history f-20 icon-ok-circled ${act.activo?'text-general':'text-gris'}`" />
                            </div>
                        </div>
                        <div v-show="index != historial.length-1" class="mx-auto h-100 py-2" :class="act.activo?'bg-general3':'bg-gris'" style="width:2px;" />
                    </div>
                    <div class="col">
                        <div class="row mx-0">
                            <div class="col px-1">
                                <p class="f-14" :class="act.activo?'text-general':'text-gris'">{{ act.content }}</p>
                                <p class="f-13">{{ act.hora | helper-fecha('hh:mm a DD MMM') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <p class="text-general f-17 my-2 mb-3 cr-pointer text-center" @click="showHistorial = !showHistorial">
                <span v-text="showHistorial ? 'Ver menos' : 'Ver más'" />
                <i :class="`${ showHistorial ? 'icon-angle-up' : 'icon-angle-down' } text-general`" />
            </p>
        </div>
        <!-- linea de tiempo -->
        <!-- Mas informacion -->
        <div class="border-bottom py-2 p-2">
            <div class="row mx-0 align-items-center cr-pointer">
                <div style="width:41px;">
                    <i class="icon-pedidos f-22 text-gr-general" />
                </div>
                <div class="col pl-0 text-general f-17 f-500">
                    Más Información
                </div>
            </div>
            <div class="row mx-0">
                <div style="width:41px;" />
                <div class="col pl-0 pr-0 f-16">
                    <p class="text-general f-500 mt-2">Instrucciones:</p>
                    <div class="row mx-0">
                        <div class="col px-0 f-13 text-general">
                            <p v-html="fun_caracteres(pedido.instrucciones)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-2 px-3 text-general border-bottom">
            <div class="row mx-0 px-2 text-general f-600 f-17">
                Detalle de cobro
            </div>
            <div class="row mx-0 px-2 justify-content-between f-15">
                <p>Costo de productos ({{ resumenes.final.cantidad_productos }})</p>
                <b>{{ convertMoneyTendero(resumenes.final.val_productos, pedido.idm_moneda) }}</b>
            </div>
            <div class="row mx-0 px-2 justify-content-between f-15">
                <p>Descuento</p>
                <b class="text-general-red">
                    {{ convertMoneyTendero(resumenes.final.val_descuentos, pedido.idm_moneda) }}
                </b>
            </div>
            <div v-if="resumenes.final.val_descuento_lista > 0" class="row mx-0 px-2 justify-content-between f-15">
                <p>Descuento Lista</p>
                <b class="text-general-red">
                    {{ convertMoneyTendero(resumenes.final.val_descuento_lista, pedido.idm_moneda) }}
                </b>
            </div>
            <div v-if="resumenes.final.id_cupon" class="row mx-0 px-2 justify-content-between f-15">
                <div class="d-middle">
                    <i class="icon-cupon pr-1" />
                    <p>{{ resumenes.final.codigo_cupon }}</p>
                </div>
                <b class="text-general-red">
                    {{ convertMoneyTendero(resumenes.final.val_cupon, pedido.idm_moneda) }}
                </b>
            </div>
            <div v-show="convenio" class="row mx-0 px-2 justify-content-between f-15">
                <p>Descuento Nomina</p>
                <b class="text-general-red">
                    {{ convertMoneyTendero(resumenes.final.val_nomina, pedido.idm_moneda) }}
                </b>
            </div>
            <div class="row mx-0 px-2 mt-2 f-600 justify-content-between f-15 border-t-gris">
                <p>Sub Total</p>
                <p>{{ convertMoneyTendero(resumenes.final.subtotal, pedido.idm_moneda) }} </p>
            </div>
            <div class="row mx-0 px-2 justify-content-between f-15">
                <p>Costo de impuestos</p>
                <b class="text-general">
                    {{ convertMoneyTendero(resumenes.final.val_impuestos, pedido.idm_moneda) }}
                </b>
            </div>
            <div class="row mx-0 px-2 justify-content-between f-15">
                <p>Costo de envío</p>
                <b>{{ convertMoneyTendero(resumenes.final.val_domicilios, pedido.idm_moneda) }}</b>
            </div>
            <div class="row mx-0 px-2 mt-2 f-600 justify-content-between f-15 border-top pt-2">
                <p>Total a cobrar</p>
                <p>{{ convertMoneyTendero(resumenes.final.val_total, pedido.idm_moneda) }} </p>
            </div>
        </div>
        <!-- Inicio convenio nómina -->
        <div v-if="convenio" class="border-bottom text-general p-2">
            <div class="row mx-0 my-2 align-items-center">
                <div class="col-auto pl-0 pr-2">
                    <i class="icon-convenio f-22" />
                </div>
                <p class="col f-600 f-17 pl-0 text-general">Convenio de nómina</p>
            </div>
            <div class="row mx-0 align-items-center my-2">
                <div class="col-auto px-3 mr-1" />
                <img class="border br-10 bg-whitesmoke obj-cover" :src="convenio.logo" width="50" height="50" alt="" />
                <div class="col f-14 text-general">
                    <p class=" f-500 ">{{ convenio.nombre }}</p>
                    <p>{{ convenio.tipo_pago }}</p>
                    <p> Fecha Liquidación : {{ convenio.fecha_liquidacion | helper-fecha('DD MMMM YYYY') }} </p>
                </div>
            </div>
        </div>
        <!-- Fin convenio nómina -->
        <div v-show="pedido.directo && resumenes.final.ganancia > 0" class="p-2 border-bottom">
            <div class="row mx-0 my-2 ticket br-8 py-1">
                <div class="col pl-1 px-0 text-general f-15">
                    Ganancias Vendedor
                </div>
                <div class="col-auto ml-auto total-value">
                    {{ convertMoneyTendero(resumenes.final.ganancia, pedido.idm_moneda) }}
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data(){
        return{
            ver_mas:false,
            showHistorial: false
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_historial/id_pedido',
            pedido: 'pedidos/pedidos_historial/pedido',
            cliente: 'pedidos/pedidos_historial/cliente',
            historial: 'pedidos/pedidos_historial/historial',
            resumenes: 'pedidos/pedidos_historial/resumenes',
            convenio: 'pedidos/pedidos_historial/convenio',
        }),
        nombreEstado(){
            if(!this.historial.length) return ''
            let filtered = this.historial.filter(el => el.activo)
            if(!filtered.length) return ''

            return filtered[filtered.length - 1]["content"] ?? '';
        }
    },
    methods: {
        fun_caracteres(cadena){
            if(!cadena) return
            let total = cadena.length
            if(this.ver_mas){
                return this.formatearTextoHtml(cadena.substr(0,total))
            }
            return this.formatearTextoHtml(cadena.substr(0,300))
        },
    }
}
</script>
<style lang="css" scoped>
.br-12{ border-radius: 12px;}
.header-credito{ border-radius: 12px 12px 0 0px; height: 50px; }
.borders{ border-left: 1px solid #5D5D5D59; border-right: 1px solid #5D5D5D59; border-bottom: 1px solid #5D5D5D59; border-radius: 0px 0px 12px 12px;}
.text-red{ color: #FF2525; }
.text-green{ color: #28D07B; }
.line-history::before{
    display: contents !important;
}
</style>
